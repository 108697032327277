import JuryDetail from "@/models/jury-detail";
import StatusProposal from "@/models/status-proposal";
import TeamHeader from "@/models/team-header";
import { UUIDModel } from "@/models/uuid-model";
import ProposalDetail from "@/models/proposal-detail";
import CommentHeader from "@/models/comment-header";
import Voting from "@/models/voting";

export default class Proposal extends UUIDModel<Proposal> {
  public id = "";
  public phase = 0;
  public proposalTitle = "";
  public category = "";
  public createdAt = "";
  public summary = "";
  public keyword = "";
  public createdBy = "";
  public approvedAt = "";
  public finalizedAt = "";
  public finalizedBy = "";
  public order = 0;
  public score = 0;
  public juryDetail: JuryDetail[] = [];
  public proposalDetails: ProposalDetail[] = [];
  public commentHeader: CommentHeader[] = [];
  public voting: Voting[] = [];
  public isCanEntry = false;
  public currentUserRoles: any[] = [];

  public teamId = "";
  public team: TeamHeader = new TeamHeader();

  public statusId = "";
  public status: StatusProposal = new StatusProposal();
}
