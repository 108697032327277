import { UUIDModel } from "@/models/uuid-model";

export default class UserHeader extends UUIDModel<UserHeader> {
  public binusianId = "";
  public email = "";
  public name = "";
  public position = "";
  public businessUnitGroup = "";
  public businessUnit = "";
  public subBusinessUnit = "";
  public departmentGroup = "";
  public department = "";
  public extension = "";
  public handphone = "";
  public jobBand = "";
  public leadId = "";
  public role = 3;
  public roleName = "";

  public lead?: UserHeader;
  public inverseLead: UserHeader[] = [];
}
