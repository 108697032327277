import { UUIDModel } from "@/models/uuid-model";
import { UUID } from "@/models/uuid";
import UserHeader from "@/models/user-header";

export default class Voting extends UUIDModel<Voting> {
  public votingId: UUID = new UUID();
  public email = "";
  public date = "";

  public proposalId: UUID = new UUID();
  // public proposal: Proposal;
  // public proposal: Proposal;
  public user: UserHeader = new UserHeader();
  public prize = "";
}
