import * as uuidLib from "uuid";

export class UUID extends String {
  public constructor() {
    super();
    return new String(uuidLib.v4());
  }
}

UUID.prototype = new String();
