import { UUIDModel } from "@/models/uuid-model";
import { UUID } from "@/models/uuid";
import Proposal from "@/models/proposal";
import TeamDetail from "@/models/team-detail";

export default class TeamHeader extends UUIDModel<TeamHeader> {
  public id: UUID = new UUID();
  public name = "";
  public createdAt = "";
  public photo = "";

  public proposal: Proposal[] = [];
  public teamDetail: TeamDetail[] = [];
}
