import { Collection } from "@/models/collection";
import { VotingService } from "@/services/voting.service";
import { tap } from "rxjs/operators";
import Voting from "@/models/voting";
import { ActionContext, Module } from "vuex";
import { StoreData } from "@/store";
import { Paginate } from "@/models/paginate";

export interface VotingData {
  votings: Collection<Voting> | null;
  voting: Voting | null;
  proposalVoters: Paginate<Voting[]> | null;
  isHideVoting: any;
}

export default {
  namespaced: true,
  state: (): VotingData => ({
    votings: null,
    voting: null,
    proposalVoters: null,
    isHideVoting: null,
  }),
  actions: {
    getVotings: (
      context: ActionContext<VotingData, StoreData>,
      params: { year: number } | null
    ) => {
      return VotingService.getVotings(params).pipe(
        tap((votings: Collection<Voting>) => {
          context.commit("setVotings", votings);
        })
      );
    },

    getMyVotings: (
      context: ActionContext<VotingData, StoreData>,
      params: { year: number } | null
    ) => {
      return VotingService.getMyVotings(params).pipe(
        tap((votings: Collection<Voting>) => {
          context.commit("setVotings", votings);
        })
      );
    },

    getVoting: (
      context: ActionContext<VotingData, StoreData>,
      votingId: string
    ) => {
      return VotingService.getVoting(votingId).pipe(
        tap((voting: Voting) => {
          context.commit("setVoting", voting);
        })
      );
    },

    postVoting: (
      _ontext: ActionContext<VotingData, StoreData>,
      params: any
    ) => {
      return VotingService.postVoting(params);
    },

    isHideVoting: (context: ActionContext<VotingData, StoreData>) => {
      return VotingService.isHideVoting().pipe(
        tap((isHideVoting) => {
          context.commit("setHideVoting", isHideVoting);
        })
      );
    },

    changeHideVoting: (
      _context: ActionContext<VotingData, StoreData>,
      params: any
    ) => {
      return VotingService.changeHideVoting(params);
    },

    getProposalVoters: (
      context: ActionContext<VotingData, StoreData>,
      params: any
    ) => {
      return VotingService.getProposalVoters(
        params.proposalId,
        params.query
      ).pipe(
        tap((votings: Paginate<Voting[]>) => {
          console.log(votings);
          context.commit("setProposalVoters", votings);
        })
      );
    },

    updatePrize: (
      _context: ActionContext<VotingData, StoreData>,
      params: any
    ) => {
      return VotingService.updatePrize(params.votingId, params.query);
    },
  },
  mutations: {
    setVotings: (state: VotingData, votings: Collection<Voting>): void => {
      state.votings = votings;
    },

    setVoting: (state: VotingData, voting: Voting): void => {
      state.voting = voting;
    },

    setHideVoting: (state: VotingData, isHideVoting: any): void => {
      state.isHideVoting = isHideVoting;
    },

    setProposalVoters: (state: VotingData, votings: Paginate<Voting[]>): void => {
      state.proposalVoters = votings;
    },
  },
} as Module<VotingData, StoreData>;
