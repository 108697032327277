import UserHeader from "@/models/user-header";
import { AuthService } from "@/services/auth.service";
import * as Msal from "msal";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ActionContext, Module } from "vuex";
import { StoreData } from "@/store";

export interface AuthData {
  user: { user: UserHeader } | null;
}

export default {
  namespaced: true,
  state: (): AuthData => ({
    user: null,
  }),
  actions: {
    login: (): void => {
      return AuthService.login();
    },
    handleRedirectCallback: (): void => {
      return AuthService.handleRedirectCallback();
    },
    acquireTokenGraph: (): Promise<Msal.AuthResponse | void> => {
      return AuthService.acquireTokenGraph();
    },
    acquireTokenClient: (): Promise<Msal.AuthResponse | void> => {
      return AuthService.acquireTokenClient();
    },
    getUser: (
      context: ActionContext<AuthData, StoreData>
    ): Observable<{ user: UserHeader }> => {
      return AuthService.getUser().pipe(
        tap((user: { user: UserHeader }) => {
          context.commit("setUser", user);
        })
      );
    },
    logout: (context: ActionContext<AuthData, StoreData>): void => {
      AuthService.logout();
      context.commit("setUser", null);
      // this.setUser(null);
      window.axios.defaults.headers.common["Authorization"] = ``;
    },
  },
  mutations: {
    setUser: (state: AuthData, user: { user: UserHeader }): void => {
      state.user = user;
    },
  },
  getters: {
    getAccount: (): Msal.Account => {
      return AuthService.getAccount();
    },
    getMsal: (): Msal.UserAgentApplication => {
      return AuthService.getMsal();
    },
    isCommitee: (state: AuthData): boolean => {
      return (
        state.user?.user.roleName === "Commitee"
        //state.user && state.user.user && state.user.user.roleName === "Commitee"
      );
    },
    isParticipant: (state: AuthData): boolean => {
      return (
        state.user?.user.roleName === "Participant"
        // state.user &&
        // state.user.user &&
        // state.user.user.roleName === "Participant"
      );
    },
    isJury: (state: AuthData): boolean => {
      return (
        state.user?.user.roleName === "Jury"
        // state.user && state.user.user && state.user.user.roleName === "Jury"
      );
    },
  },
} as Module<AuthData, StoreData>;
