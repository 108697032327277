import Vue from "vue";
import Vuex, { Store } from "vuex";
import ProposalStore, { ProposalData } from "@/store/modules/proposal.store";
import PeriodManagementStore, { PeriodManagementData } from "@/store/modules/period-management.store";
import JudgeStore, { JudgeData } from "@/store/modules/judge.store";
import JuryDetailStore, { JuryDetailData } from "@/store/modules/jury-detail.store";
import VotingStore, { VotingData } from "@/store/modules/voting.store";
import UserStore, { UserData } from "@/store/modules/user.store";
import AuthStore, { AuthData } from "@/store/modules/auth.store";
import ReportStore, { ReportData } from "@/store/modules/report.store";
import FeedbackStore, { FeedbackData } from "@/store/modules/feedback.store";
import ActivityStore, { ActivityData } from "@/store/modules/activity.store";
import Common, { CommonData } from "@/store/common";
// import VueMoment from "vue-moment";

Vue.use(Vuex);
// Vue.use(VueMoment);

export interface StoreData {
  Common: CommonData;
  ActivityStore: ActivityData;
  AuthSrore: AuthData;
  FeedbackStore: FeedbackData;
  JudgeStore: JudgeData;
  JuryDetailStore: JuryDetailData;
  PeriodManagementStore: PeriodManagementData;
  ProposalStore: ProposalData;
  ReportStore: ReportData;
  UserStore: UserData;
  VotingStore: VotingData;
}

export default new Vuex.Store<StoreData>({
  modules: {
    ProposalStore,
    PeriodManagementStore,
    Common,
    JudgeStore,
    VotingStore,
    UserStore,
    ReportStore,
    JuryDetailStore,
    AuthStore,
    FeedbackStore,
    ActivityStore,
  },
}) as Store<StoreData>;
