import { UUIDModel } from "@/models/uuid-model";
import { UUID } from "@/models/uuid";
import Proposal from "@/models/proposal";

export default class StatusProposal extends UUIDModel<StatusProposal> {
  public statusId: UUID = new UUID();
  public name = "";

  public proposal: Proposal[] = [];
}
