import { Observable } from "rxjs";
import { mapToArrayClass, mapToClass, mapToData } from "@/client/transformers";
import ScoringDetail from "@/models/scoring-detail";
import JuryDetail, { FinalizeScoreEntryParams } from "@/models/jury-detail";
import { Response } from "@/models/Response";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { AxiosRequestConfig } from "axios";

export class JuryDetailService {
  public static getJuryDetail(
    params?: any,
    options: AxiosRequestConfig = {}
  ): Observable<JuryDetail> {
    if (params !== undefined) {
      Object.assign(options, { params });
    }
    return window.axios
      .get(`/jurydetail`, options)
      .pipe(mapToData(), mapToClass(JuryDetail));
  }

  public static getProposalScoringDetails(
    proposalId: string
  ): Observable<Record<string, string | number>[]> {
    return window.axios.get(`/proposal/${proposalId}/scoringdetail`).pipe(
      mapToData() //, mapToArrayClass(ScoringDetail)
    );
  }

  public static submitScoringDetails(
    params?: any,
    options: AxiosRequestConfig = {}
  ): AxiosObservable<Response | ScoringDetail[]> {
    return window.axios.put(`/jurydetail/ScoreEntry`, params, options);
  }

  public static finalizeScoringDetails(
    params: FinalizeScoreEntryParams
  ): AxiosObservable<Response> {
    return window.axios.post(`/jurydetail/ScoreEntry/Finalize`, params);
  }

  public static sendApprovalCode(): AxiosObservable<Response> {
    return window.axios.post(`/jurydetail/ApprovalCode`);
  }

  // public static finalizeJudgeDetail(params?: any): Observable<any> {
  //   return window.axios.post(`/jurydetail/finalize`, params);
  // }
}
