import { OperatorFunction, UnaryFunction } from "rxjs/interfaces";
import { Observable } from "rxjs/Observable";
import { pipe } from "rxjs/util/pipe";
import { throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { plainToClass } from "class-transformer";
import { Collection } from "@/models/collection";
import { ClassConstructor } from "class-transformer/types/interfaces/class-constructor.type";

export function mapToArrayClass<T>(type: {
  new (): T;
}): UnaryFunction<Observable<any>, Observable<T[]>> {
  return pipe(
    map((data) => {
      if (Array.isArray(data)) {
        return data.map((item) => plainToClass(type, item));
      }

      throw new Error("The data is not an array");
    })
  );
}

export function mapToArrayCollection<T>(): UnaryFunction<
  Observable<any>,
  Observable<Array<Collection<T>>>
> {
  return pipe(
    map((data) => {
      const collectionType = (null as unknown) as { new (): Collection<T> };

      if (Array.isArray(data)) {
        return plainToClass(collectionType, data);
      }

      throw new Error("The data is not an array");
    })
  );
}

export function mapToClass<T>(type: {
  new (): T;
}): UnaryFunction<Observable<any>, Observable<T>> {
  return pipe(
    map((data) => {
      if (!Array.isArray(data)) {
        return plainToClass(type, <T>data);
      }

      throw new Error("The data is an array");
    })
  );
}

export function mapToCollection<T>(): UnaryFunction<
  Observable<any>,
  Observable<Collection<T>>
> {
  return pipe(
    map((data) => {
      const collectionType = (null as unknown) as { new (): Collection<T> };

      if (!Array.isArray(data)) {
        return plainToClass(collectionType, data);
      }

      throw new Error("The data is an array");
    })
  );
}

export function mapToData<T extends any>(): UnaryFunction<
  Observable<any>,
  Observable<any>
> {
  return pipe(
    map((res: { data: T }) => {
      if (res.data) {
        return res.data;
      }

      throw new Error("There are no body to be transformed");
    })
  );
}

export function mapToPagination<T extends any>(): UnaryFunction<
  Observable<any>,
  Observable<{ data: T; pagination: any }>
> {
  return pipe(
    map((res: { data: T; headers: Record<string, any> }) => {
      const ret = {
        data: {} as T,
        pagination: {},
      };

      if (res.data) {
        ret.data = res.data;
      }

      if (res.headers) {
        ret.pagination = {
          current_page: +res.headers["x-pagination-current-page"],
          total_page: +res.headers["x-pagination-page-count"],
          per_page: +res.headers["x-pagination-per-page"],
          total_count: +res.headers["x-pagination-total-count"],
          approved: +res.headers["x-total-approve"],
          finalized: +res.headers["x-total-finalize"],
          proposal: +res.headers["x-total-proposal"],
        };
      }

      return ret;
    })
  );
}

export function filterObject<T>(object: T): T {
  const obj = {} as Record<string, any>;
  Object.assign(obj, object);

  for (const key in obj) {
    if (key in obj && obj[key] === "") {
      delete obj[key];
    }
  }

  return obj as T;
}
