import { JudgeService } from "@/services/judge.service";
import { tap } from "rxjs/operators";
import UserHeader from "@/models/user-header";
import { ActionContext, Module } from "vuex";
import { Observable } from "rxjs";
import { StoreData } from "@/store";

export interface JudgeData {
  judges: UserHeader[];
  judge: UserHeader | null;
}

export default {
  namespaced: true,
  state: (): JudgeData => ({
    judges: [],
    judge: null,
  }),
  actions: {
    getJudges: (
      context: ActionContext<JudgeData, StoreData>
    ): Observable<UserHeader[]> => {
      return JudgeService.getJudges().pipe(
        tap((judges: UserHeader[]) => {
          console.log("setjudge", judges);
          context.commit("setJudges", judges);
        })
      );
    },

    getJudge: (
      context: ActionContext<JudgeData, StoreData>,
      binusianId: string
    ): Observable<UserHeader> => {
      return JudgeService.getJudge(binusianId).pipe(
        tap((judge: UserHeader) => {
          context.commit("setJudge", judge);
        })
      );
    },
  },
  mutations: {
    setJudges: (state: JudgeData, judges: UserHeader[]): void => {
      state.judges = judges;
    },

    setJudge: (state: JudgeData, judge: UserHeader): void => {
      state.judge = judge;
    },
  },
} as Module<JudgeData, StoreData>;
