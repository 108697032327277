import { Observable } from "rxjs";
import { mapToData } from "@/client/transformers";
import Feedback, {
  FeedbackParams,
  FeedbackReplyParams,
} from "@/models/feedback";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { Response } from "@/models/Response";

export class FeedbackService {
  public static getFeedbacks(/* query: any */): Observable<Feedback[]> {
    return window.axios
      .get("/feedback" /* , { params: query }*/)
      .pipe(mapToData());
  }

  public static getFeedback(feedbackId: string): Observable<Feedback> {
    return window.axios.get(`/feedback/${feedbackId}`).pipe(mapToData());
  }

  public static postFeedback(
    params: FeedbackParams
  ): AxiosObservable<Feedback> {
    return window.axios.post("/feedback", params);
  }

  public static replyFeedback(
    feedbackId: string,
    params: FeedbackReplyParams
  ): AxiosObservable<Response> {
    return window.axios.put(`/feedback/${feedbackId}/reply`, params);
  }

  public static closeFeedback(feedbackId: string): AxiosObservable<Response> {
    return window.axios.put(`/feedback/${feedbackId}/close`);
  }
}
