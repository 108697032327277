import { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import store from "@/store";
import { catchError, tap } from "rxjs/operators";
import { combineLatest, of } from "rxjs";

// commitee
const CommiteeTemplate = () =>
  import("@/templates/commitee/commitee.template.vue");
const CommiteeIndex = () =>
  import("@/components/commitee/index/commitee-index.vue");
const ListProposalFirst = () =>
  import("@/components/commitee/list-proposal/list-proposal-first.vue");
const ListProposalSecond = () =>
  import("@/components/commitee/list-proposal/list-proposal-second.vue");
const ListProposalThird = () =>
  import("@/components/commitee/list-proposal/list-proposal-third.vue");
const AssignJudgesFirst = () =>
  import("@/components/commitee/assign-judges/assign-judges-first.vue");
const AssignJudgesSecond = () =>
  import("@/components/commitee/assign-judges/assign-judges-second.vue");
const AssignJudgesThird = () =>
  import("@/components/commitee/assign-judges/assign-judges-third.vue");
const VoteResult = () =>
  import("@/components/commitee/vote-result/vote-result.vue");
const UserManagementUser = () =>
  import("@/components/commitee/user-management/user-management-user.vue");
const UserManagementGroup = () =>
  import("@/components/commitee/user-management/user-management-group.vue");
const GenerateReport = () =>
  import("@/components/commitee/generate-report/generate-report.vue");
const CommiteeFeedback = () =>
  import("@/components/commitee/feedback/commitee-feedback.vue");
const CommiteeFeedbackDetail = () =>
  import("@/components/commitee/feedback/commitee-feedback-detail.vue");
const ProposalFirst = () =>
  import("@/components/commitee/proposal/proposal-first.vue");
const ProposalScore = () =>
  import("@/components/commitee/proposal/proposal-score.vue");
const ProposalSecond = () =>
  import("@/components/commitee/proposal/proposal-second.vue");
const VoterList = () =>
  import("@/components/commitee/voter-list/voter-list.vue");
const DetailActivity = () =>
  import("@/components/commitee/activity/detail-activity.vue");
const CommiteeActivity = () =>
  import("@/components/commitee/activity/activity.vue");

const commiteeMiddleware = async (
  _to: Route,
  _from: Route,
  next: NavigationGuardNext<Vue>
) => {
  const res = await store.dispatch("AuthStore/getUser");
  res
    .pipe(
      tap(() => {
        if (store.getters["AuthStore/isCommitee"]) {
          return next();
        } else {
          return next("/");
        }
      }),
      catchError((_error: any) => {
        return of(next("/"));
      })
    )
    .subscribe();
};

export default {
  path: "/commitee",
  component: CommiteeTemplate,
  beforeEnter: commiteeMiddleware,
  children: [
    {
      path: "/",
      name: "Home Commitee",
      component: CommiteeIndex,
    },
    {
      path: "list-proposal/1",
      name: "Proposals Phase 1",
      component: ListProposalFirst,
    },
    {
      path: "list-proposal/2",
      name: "Proposals Phase 2",
      component: ListProposalSecond,
    },
    {
      path: "list-proposal/3",
      name: "Proposals Phase 3",
      component: ListProposalThird,
    },
    {
      path: "list-proposal/1/proposal/:id",
      component: ProposalFirst,
    },
    {
      path: "list-proposal/proposal/:id/score",
      component: ProposalScore,
    },
    {
      path: "list-proposal/2/proposal/:id",
      component: ProposalSecond,
    },
    {
      path: "voter-list/:id?",
      component: VoterList,
    },
    {
      path: "assign-judges/1",
      name: "Assign Judges Phase 1",
      component: AssignJudgesFirst,
    },
    {
      path: "assign-judges/2",
      name: "Assign Judges Phase 2",
      component: AssignJudgesSecond,
    },
    {
      path: "assign-judges/3",
      name: "Assign Judges Phase 3",
      component: AssignJudgesThird,
    },
    {
      path: "vote-result",
      component: VoteResult,
    },
    {
      path: "user-management/user",
      name: "List User",
      component: UserManagementUser,
    },
    {
      path: "user-management/group",
      name: "List Group",
      component: UserManagementGroup,
    },
    {
      path: "generate-report",
      name: "Report",
      component: GenerateReport,
    },
    {
      path: "feedback",
      name: "List Feedback commitee",
      component: CommiteeFeedback,
    },
    {
      path: "feedback/:id",
      component: CommiteeFeedbackDetail,
    },
    {
      path: "activity",
      name: "List Activity",
      component: CommiteeActivity,
    },
    {
      path: "activity/:id",
      component: DetailActivity,
    },
  ],
} as RouteConfig;
