import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import store from "@/store";
import commiteeRouter from "@/router/commitee.router";
import juryRouter from "@/router/jury.router";
import participantRouter from "@/router/participant.router";

Vue.use(VueRouter);

const router: VueRouter = new VueRouter({
  mode: "history",
  linkExactActiveClass: "active",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/Test",
      component: () => import("@/views/Coba.vue"),
    },
    {
      path: "/",
      component: () => import("@/components/index.vue"),
      // beforeEnter: (
      //   _to: Route,
      //   _from: Route,
      //   next: NavigationGuardNext<Vue>
      // ) => {
      //   store.dispatch("AuthStore/getUser").then((res) => {
      //     console.log("res", res);
      //     res
      //       .pipe(
      //         tap((user: any) => {
      //           console.log(user);
      //           // this.routeAuth(user);
      //           next("/participant");
      //         }),
      //         catchError(async () => {
      //           next("/login");
      //           return of(null);
      //         })
      //       )
      //       .subscribe();
      //   });
      // },
    },
    {
      path: "/score/:id",
      component: () =>
        import("@/components/common/direct-score/direct-score.vue"),
    },
    // {
    //     path: '/jury/score/:id',
    //     component: JuryScoring,
    // },
    {
      path: "/login",
      component: () => import("@/components/login/login.vue"),
      beforeEnter: (
        _to: Route,
        _from: Route,
        next: NavigationGuardNext<Vue>
      ) => {
        if (store.getters["AuthStore/getAccount"]) {
          next("/");
        } else {
          next();
        }
      },
    },
    {
      path: "/registration",
      component: () => import("@/views/Registration.vue"),
    },
    commiteeRouter,
    participantRouter,
    juryRouter,
    {
      path: "*",
      redirect: "/",
    },
  ],
  scrollBehavior(_to: Route, _from: Route, _savedPosition: any) {
    return { x: 0, y: 0 };
  },
});

// router.beforeEach((to, from, next) => {
//   console.log("before", to, from);
//   next();
// });

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title =
      "💡 Continuous Innovation" + (to.name ? " - " + to.name : "");
  });
});

export default router;
