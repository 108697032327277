import { UUIDModel } from "@/models/uuid-model";
import { UUID } from "@/models/uuid";

export default class PeriodManagement extends UUIDModel<PeriodManagement> {
  public id: UUID = new UUID();
  public year = "";
  public period = 0;
  public description = "";
  public startAt = "";
  public endAt = "";
  public createdAt = "";
  public sendEmailBy = "";
  public sendEmailAt = "";
  public isActive = false;
}
