import { BvMsgBoxOptions, BvToastOptions } from "bootstrap-vue";
import { Observable, of } from "rxjs";
import Vue, { VueConstructor } from "vue";
import Component from "vue-class-component";

@Component
export class ModalMessage extends Vue {
  public async confirm(
    message: string,
    options?: BvMsgBoxOptions
  ): Promise<boolean | null> {
    if (!this.$bvModal) {
      return null;
    }
    options = Object.assign(
      {
        title: "Confirmation",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      },
      options ?? {}
    );
    return await this.$bvModal.msgBoxConfirm(message, options);
  }

  public toastSuccess(message: string, options?: BvToastOptions): void {
    if (!this.$bvToast) {
      return;
    }
    options = Object.assign(
      {
        title: "Success",
        variant: "success",
        solid: true,
      },
      options
    );
    this.$bvToast.toast(message, options);
  }

  public toastError(message: string, options?: BvToastOptions): void {
    if (!this.$bvToast) {
      return;
    }
    options = Object.assign(
      {
        title: "Error",
        variant: "danger",
        solid: true,
      },
      options
    );
    this.$bvToast.toast(message, options);
  }

  public catchError(error: any): Observable<null> {
    const { errors, title, message } = error.response.data;
    if (errors) {
      Object.keys(errors).forEach((key: string) => {
        this.toastError(errors[key]);
      });
      return of(null);
    }
    this.toastError(title || message || error.response.data);
    return of(null);
  }
}

const ModalConfirmation = {
  install(Vue: VueConstructor) {
    Vue.mixin(ModalMessage);
  },
};

Vue.use(ModalConfirmation);

declare module "vue/types/vue" {
  interface Vue {
    confirm(
      message: string,
      options?: BvMsgBoxOptions
    ): Promise<boolean | null>;
    toastSuccess(message: string, options?: BvToastOptions): void;
    toastError(message: string, options?: BvToastOptions): void;
    catchError(error: any): Observable<null>;
  }
}
