import { Observable } from "rxjs";
import Activity from "@/models/activity";
import {
  filterObject,
  mapToArrayClass,
  mapToData,
  mapToPagination,
} from "@/client/transformers";
import { Paginate } from "@/models/paginate";
import ActivityDetail from "@/models/activity-detail";

export class ActivityService {
  public static getActivities(params: any): Observable<Paginate<Activity[]>> {
    return window.axios
      .get(`/activity`, { params: filterObject(params) })
      .pipe(mapToPagination());
  }

  public static getActivityDetail(
    id: string,
    query: any
  ): Observable<Paginate<ActivityDetail[]>> {
    return window.axios
      .get(`/activity/${id}/detail`, { params: filterObject(query) })
      .pipe(mapToPagination());
  }
}
