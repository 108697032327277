import { UserService } from "@/services/user.service";
import { tap } from "rxjs/operators";
import UserHeader from "@/models/user-header";
import { Paginate } from "@/models/paginate";
import UserSuggestion from "@/models/user-suggestion";
import TeamHeader from "@/models/team-header";
import { ActionContext, Module } from "vuex";
import { StoreData } from "@/store";

export interface UserData {
  users: Paginate<UserHeader[]> | null;
  user: UserHeader | null;
  teams: Paginate<TeamHeader[]> | null;
  team: TeamHeader | null;
  jobBands: string[];
  roles: string[];
  userSuggestion: UserSuggestion[];
}

export default {
  namespaced: true,
  state: (): UserData => ({
    users: null,
    user: null,
    teams: null,
    team: null,
    jobBands: [],
    roles: [],
    userSuggestion: [],
  }),
  actions: {
    getUsers: async (
      context: ActionContext<UserData, StoreData>,
      params: any
    ): Promise<any> => {
      return UserService.getUsers(params).pipe(
        tap((users: Paginate<UserHeader[]>) => {
          context.commit("setUsers", users);
        })
      );
    },

    getUser: (
      context: ActionContext<UserData, StoreData>,
      userId: string
    ): any => {
      return UserService.getUser(userId).pipe(
        tap((user: UserHeader) => {
          context.commit("setUser", user);
        })
      );
    },

    updateUser: (
      _context: ActionContext<UserData, StoreData>,
      params: { query: any; body: any }
    ): any => {
      return UserService.updateUser(params.query, params.body);
    },

    getTeams: async (
      context: ActionContext<UserData, StoreData>,
      params: any
    ): Promise<any> => {
      return UserService.getTeams(params).pipe(
        tap((teams: Paginate<TeamHeader[]>) => {
          context.commit("setTeams", teams);
        })
      );
    },

    getTeam: (
      context: ActionContext<UserData, StoreData>,
      teamId: string
    ): any => {
      return UserService.getTeam(teamId).pipe(
        tap((team: TeamHeader) => {
          context.commit("setTeam", team);
        })
      );
    },

    updateTeam: (
      _context: ActionContext<UserData, StoreData>,
      params: any
    ): any => {
      return UserService.updateTeam(params);
    },

    postUser: (
      _context: ActionContext<UserData, StoreData>,
      params: any
    ): any => {
      return UserService.postUser(params);
    },

    deleteUser: (
      _context: ActionContext<UserData, StoreData>,
      userId: string
    ): any => {
      return UserService.deleteUser(userId);
    },

    getJobBands: (context: ActionContext<UserData, StoreData>): any => {
      return UserService.getJobBands().pipe(
        tap((jobBands: string[]) => {
          context.commit("setJobBands", jobBands);
        })
      );
    },

    getRoles: (context: ActionContext<UserData, StoreData>): any => {
      return UserService.getRoles().pipe(
        tap((roles: string[]) => {
          context.commit("setRoles", roles);
        })
      );
    },

    getUserInformation: (
      _context: ActionContext<UserData, StoreData>,
      email: string
    ): any => {
      return UserService.getUserInformation(email);
    },

    getUserSuggestion: (
      context: ActionContext<UserData, StoreData>,
      keyword: string
    ): any => {
      return UserService.getUserSuggestion(keyword).pipe(
        tap((userSuggestion: UserSuggestion[]) => {
          context.commit("setUserSuggestion", userSuggestion);
        })
      );
    },
  },
  mutations: {
    setUsers: (state: UserData, users: Paginate<UserHeader[]>): void => {
      state.users = users;
    },

    setUser: (state: UserData, user: UserHeader): void => {
      state.user = user;
    },

    setTeams: (state: UserData, teams: Paginate<TeamHeader[]>): void => {
      state.teams = teams;
    },

    setTeam: (state: UserData, team: TeamHeader): void => {
      state.team = team;
    },

    setJobBands: (state: UserData, jobBands: string[]): void => {
      state.jobBands = jobBands;
    },

    setRoles: (state: UserData, roles: string[]): void => {
      state.roles = roles;
    },

    setUserSuggestion: (
      state: UserData,
      userSuggestion: UserSuggestion[]
    ): void => {
      state.userSuggestion = userSuggestion;
    },
  },
} as Module<UserData, StoreData>;
