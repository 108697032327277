import { StoreData } from "@/store";
import { Module } from "vuex";

export interface CommonData {
  currentYear: number;
  year: number;
  keyword: string;
  formatDate: string;
  categories: { label: string; description: string }[];
  shirtSizes: { label: string; size: string }[];
  phases: string[];
}

export default {
  namespaced: true,
  state: (): CommonData => ({
    currentYear: new Date().getFullYear(),
    year: new Date().getFullYear(),
    keyword: "",
    formatDate: "DD MMM YYYY",
    categories: [
      {
        label: "Product / Service",
        description:
          "The introduction of a good or service that is new or significantly improved with respect to its characteristics or intended uses. This includes significant improvements in technical specifications, components, and materials, incorporated software, user-friendliness or other functional characteristics.",
      },
      {
        label: "Process",
        description:
          "The implementation of a new or significantly improved production or delivery method. This includes significant changes in techniques, equipment and/or software.",
      },
      {
        label: "Social",
        description:
          "Social innovation is the process of addressing the world’s most pressing challenges with “novel solutions . . . that [are] better than current solutions, new to the world, and [benefit] society as a whole and not just a single entity.”",
      },
      {
        label: "Business model",
        description:
          "Business model innovation is the search for new logic of the firm and new ways to create and capture value for its stakeholders; it focuses primarily on finding new ways to generate revenues and define value propositions for customers, suppliers, and partners.",
      },
    ],
    shirtSizes: [
      // {
      //     label: 'XS',
      //     size: '62x44 cm',
      // },
      {
        label: "S",
        size: "65x47 cm",
      },
      {
        label: "M",
        size: "69x50 cm",
      },
      {
        label: "L",
        size: "72x53 cm",
      },
      {
        label: "XL",
        size: "75x56 cm",
      },
      {
        label: "XXL",
        size: "79x59 cm",
      },
    ],
    phases: [
      "Phase 1 - Selection & Submission",
      "Phase 1 - Scoring for Top 10 Selections",
      "Phase 2 - Innovation Camp & Top 10 Presentation",
      "Phase 2 - Scoring for Top 3 Selections",
      "Phase 3 - Grand Final & Awarding",
    ],
  }),
  actions: {},
  mutations: {
    setYear: (state: CommonData, year: number): void => {
      state.year = year;
    },

    setKeyword: (state: CommonData, keyword: string): void => {
      state.keyword = keyword;
    },
  },
} as Module<CommonData, StoreData>;
