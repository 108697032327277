import { UUIDModel } from "@/models/uuid-model";

export default class Report extends UUIDModel<Report> {
  public teamName = "";
  public proposalTitle = "";
  public category = "";
  public phase = 1;
  public status = "";
  public name = "";
  public role = "";
  public department = "";
  public businessUnit = "";
}
