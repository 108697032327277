import { Observable } from "rxjs";
import { mapToArrayClass, mapToData } from "@/client/transformers";
import Report from "@/models/report";

export class ReportService {
  public static getReports(params: any = null): Observable<Report[]> {
    return window.axios
      .get(`/report`, { params })
      .pipe(mapToData(), mapToArrayClass(Report));
  }

  public static downloadReport(reportType: string, year: any): Observable<any> {
    return window.axios.get(`/report/excel/${reportType}`, {
      params: { year },
      responseType: "blob",
    });
  }
}
