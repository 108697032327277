import { tap } from "rxjs/operators";
import { PeriodManagementService } from "@/services/period-management.service";
import PeriodManagement from "@/models/period-management";
import { Observable, of } from "rxjs";
import { ActionContext, Module } from "vuex";
import { StoreData } from "@/store";

export interface PeriodManagementData {
  periodManagements: PeriodManagement[];
  currentPeriod: number;
  year: number;
}

export default {
  namespaced: true,
  state: (): PeriodManagementData => ({
    periodManagements: [],
    currentPeriod: -1,
    year: -1,
  }),
  actions: {
    getPeriodManagements: (
      context: ActionContext<PeriodManagementData, StoreData>
    ): Observable<PeriodManagement[]> => {
      return PeriodManagementService.getPeriodManagements().pipe(
        tap((periodManagements: PeriodManagement[]) => {
          const currentPeriod = periodManagements.filter(
            (item) => item.isActive
          )[0].period;
          console.log("currentPeriod", currentPeriod);
          //const currentPeriod = periodManagements.findIndex((item) => item.active);
          context.commit("setCurrentPeriod", currentPeriod - 1);
          context.commit("setPeriodManagements", periodManagements);
        })
      );
    },

    getPeriodManagementsByYear: (
      context: ActionContext<PeriodManagementData, StoreData>,
      { year, options = {} }: { year: string | number; options: any }
    ): Observable<PeriodManagement[]> => {
      if (
        context.state.year === year &&
        context.state.periodManagements.length > 0
      ) {
        return of(context.state.periodManagements);
      }
      return PeriodManagementService.getPeriodManagementsByYear(
        year,
        options
      ).pipe(
        tap((periodManagements: PeriodManagement[]) => {
          const currentPeriod = periodManagements.filter(
            (item) => item.isActive
          )[0].period;
          // const currentPeriod = periodManagements.findIndex((item) => item.active);
          context.commit("setCurrentPeriod", currentPeriod - 1);
          context.commit("setCurrentYear", year);
          context.commit("setPeriodManagements", periodManagements);
        })
      );
    },

    editTime: (
      context: ActionContext<PeriodManagementData, StoreData>,
      params: any
    ): Observable<PeriodManagement> => {
      context.state.year = -1;
      return PeriodManagementService.editTime(params);
    },

    startPeriod: (
      context: ActionContext<PeriodManagementData, StoreData>,
      periodId: string
    ): Observable<PeriodManagement> => {
      context.state.year = -1;
      return PeriodManagementService.startPeriod(periodId);
    },

    sendEmail: (
      context: ActionContext<PeriodManagementData, StoreData>,
      periodId: string
    ): Observable<PeriodManagement> => {
      context.state.year = -1;
      return PeriodManagementService.sendEmail(periodId);
    },
  },
  mutations: {
    setPeriodManagements: (
      state: PeriodManagementData,
      periodManagements: PeriodManagement[]
    ): void => {
      state.periodManagements = periodManagements;
    },

    setCurrentPeriod: (
      state: PeriodManagementData,
      currentPeriod: number
    ): void => {
      state.currentPeriod = currentPeriod;
    },
    setCurrentYear: (state: PeriodManagementData, year: number): void => {
      state.year = year;
    },
  },
  getters: {
    isParticipantPeriod: (state: PeriodManagementData): boolean => {
      const period = [0, 2, 4];
      return state.currentPeriod !== -1 && period.includes(state.currentPeriod);
    },
    isJuryPeriod: (state: PeriodManagementData): boolean => {
      const period = [1, 3, 5];
      return state.currentPeriod !== -1 && period.includes(state.currentPeriod);
    },
  },
} as Module<PeriodManagementData, StoreData>;
