import Feedback, {
  FeedbackParams,
  FeedbackReplyParams,
} from "@/models/feedback";
import { FeedbackService } from "@/services/feedback.service";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Response } from "@/models/Response";
import { ActionContext, Module } from "vuex";
import { StoreData } from "@/store";

export interface FeedbackData {
  feedbacks: Feedback[];
  feedback: Feedback | null;
}

export default {
  namespaced: true,
  state: (): FeedbackData => ({
    feedbacks: [],
    feedback: null,
  }),
  actions: {
    getFeedbacks: (
      context: ActionContext<FeedbackData, StoreData>
      /* params: any */
    ): Observable<Feedback[]> => {
      return FeedbackService.getFeedbacks(/* params */).pipe(
        tap((feedbacks: Feedback[]) => {
          context.commit("setFeedbacks", feedbacks);
        })
      );
    },

    getFeedback: (
      context: ActionContext<FeedbackData, StoreData>,
      feedbackId: string
    ): Observable<Feedback> => {
      return FeedbackService.getFeedback(feedbackId).pipe(
        tap((feedback: Feedback) => {
          context.commit("setFeedback", feedback);
        })
      );
    },

    postFeedback: (
      _context: ActionContext<FeedbackData, StoreData>,
      params: FeedbackParams
    ): AxiosObservable<Feedback> => {
      return FeedbackService.postFeedback(params);
    },

    replyFeedback: (
      _context: ActionContext<FeedbackData, StoreData>,
      params: { feedbackId: string; query: FeedbackReplyParams }
    ): AxiosObservable<Response> => {
      return FeedbackService.replyFeedback(params.feedbackId, params.query);
    },

    closeFeedback: (
      _context: ActionContext<FeedbackData, StoreData>,
      feedbackId: string
    ): AxiosObservable<Response> => {
      return FeedbackService.closeFeedback(feedbackId);
    },
  },
  mutations: {
    setFeedbacks: (state: FeedbackData, feedbacks: Feedback[]): void => {
      state.feedbacks = feedbacks;
    },
    setFeedback: (state: FeedbackData, feedback: Feedback): void => {
      state.feedback = feedback;
    },
  },
} as Module<FeedbackData, StoreData>;
