import { from, Observable } from "rxjs";
import { mapToArrayClass, mapToClass, mapToData } from "@/client/transformers";
import UserHeader from "@/models/user-header";
import { map, tap } from "rxjs/operators";

export class JudgeService {
  public static getJudges(): Observable<UserHeader[]> {
    return window.axios
      .get(`/judge`)
      .pipe(mapToData(), mapToArrayClass(UserHeader));
  }

  public static getJudge(binusianId: string): Observable<UserHeader> {
    return window.axios
      .get(`/judge/${binusianId}`)
      .pipe(mapToData(), mapToClass(UserHeader));
  }
}
