import Axios from "axios-observable";
import { AuthService } from "@/services/auth.service";
import { AuthError, AuthResponse } from "msal";
import config from "@/client/config";
import router from "@/router/router";

window.axios = Axios.create({
  baseURL: `${config.baseUrl}/api`,
  withCredentials: true,
});

window.axios.interceptors.request.use(async (config: any) => {
  if (config.withCredentials && config.withCredentials === true) {
    const res = await AuthService.acquireTokenClient();
    // console.log('client', res, (res as AuthResponse).accessToken, (res as AuthResponse).idToken.rawIdToken);
    if ((res as AuthResponse).idToken) {
      // window[msalConfig.cache.cacheLocation].setItem('msal.idtoken', res.accessToken);
      config.headers.Authorization = `Bearer ${
        (res as AuthResponse).idToken.rawIdToken
      }`;
    }
    if (res instanceof AuthError) {
      return Promise.reject(res);
    }
  }
  // console.log('config', config);
  return config;
});

window.axios.interceptors.response.use(undefined, (err: any) => {
  if (
    err instanceof AuthError ||
    (err.response && err.response.status === 401)
  ) {
    // AuthService.logout();
    router.push("/login");
  }
  return Promise.reject(err);
});
