import { Observable } from "rxjs";
import {
  filterObject,
  mapToArrayClass,
  mapToClass,
  mapToCollection,
  mapToData,
  mapToPagination,
} from "@/client/transformers";
import Voting from "@/models/voting";
import { Collection } from "@/models/collection";
import { Paginate } from "@/models/paginate";

export class VotingService {
  public static getVotings(
    params: { year: number } | null
  ): Observable<Collection<Voting>> {
    return window.axios
      .get(`/voting`, { params: params })
      .pipe(mapToData(), mapToCollection<Voting>());
  }

  public static getMyVotings(
    params: { year: number } | null
  ): Observable<Collection<Voting>> {
    return window.axios
      .get(`/voting/me`, { params: params })
      .pipe(mapToData(), mapToCollection<Voting>());
  }

  public static getVoting(votingId: string): Observable<Voting> {
    return window.axios
      .get(`/voting/${votingId}`)
      .pipe(mapToData(), mapToClass(Voting));
  }

  public static postVoting(params: any): Observable<Voting> {
    return window.axios
      .post(`/voting`, params)
      .pipe(mapToData(), mapToClass(Voting));
  }

  public static update(votingId: string, params: any): Observable<Voting> {
    return window.axios
      .put(`/voting/${votingId}`, params)
      .pipe(mapToData(), mapToClass(Voting));
  }

  public static deleteVoting(votingId: string): Observable<Voting> {
    return window.axios
      .delete(`/voting/${votingId}`)
      .pipe(mapToData(), mapToClass(Voting));
  }

  public static isHideVoting(): Observable<any> {
    return window.axios.get(`/IAHideVoting`).pipe(mapToData());
  }

  public static changeHideVoting(params: any): Observable<any> {
    return window.axios.put("/IAHideVoting", params);
  }

  public static getProposalVoters(
    proposalId: string | null,
    params: any
  ): Observable<Paginate<Voting[]>> {
    return (
      window.axios
        .get(
          proposalId ? `/voting/proposal/${proposalId}` : "/voting/proposal/",
          { params: filterObject(params) }
        )
        // .pipe(mapToData(), mapToArrayClass(Voting));
        .pipe(mapToPagination())
    );
  }

  public static updatePrize(votingId: string, params: any): Observable<any> {
    return window.axios.put(`/voting/prize`, params, { params: { votingId } });
  }
}
