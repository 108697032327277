import { UUIDModel } from "@/models/uuid-model";
import { UUID } from "@/models/uuid";
import Proposal from "@/models/proposal";

export default class CommentHeader extends UUIDModel<CommentHeader> {
  public id: UUID = new UUID();
  public juryEmail = "";
  public sectionId = 0;
  public comment = "";

  public proposalId: UUID = new UUID();
  public proposal: Proposal = new Proposal();
}
