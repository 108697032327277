import {
  NavigationGuard,
  NavigationGuardNext,
  Route,
  RouteConfig,
} from "vue-router";
import store, { StoreData } from "@/store";
import { tap } from "rxjs/operators";
import { combineLatest } from "rxjs";
import { Store } from "vuex";

const JuryTemplate = () => import("@/templates/jury/jury.template.vue");
const JuryIndex = () => import("@/components/jury/index/jury-index.vue");
const JuryProposal = () =>
  import("@/components/jury/proposal/jury-proposal.vue");
const JuryScoring = () => import("@/components/jury/scoring/scoring.vue");

const juryMiddleware: NavigationGuard = (
  to: Route,
  _from: Route,
  next: NavigationGuardNext<Vue>
) => {
  const year = (store as Store<StoreData>).state.Common.currentYear;
  if (to.name === "scoring") return next(); // by-pass
  Promise.all([
    store.dispatch("AuthStore/getUser"),
    store.dispatch("PeriodManagementStore/getPeriodManagementsByYear", {
      year,
    }),
  ]).then((res) => {
    combineLatest(res)
      .pipe(
        tap(() => {
          if (
            store.getters["AuthStore/isJury"] &&
            store.getters["PeriodManagementStore/isJuryPeriod"]
          ) {
            return next();
          } else {
            return next("/");
          }
        })
      )
      .subscribe();
  });

  return next();
};

export default {
  path: "/jury",
  component: JuryTemplate,
  beforeEnter: juryMiddleware,
  children: [
    {
      path: "/",
      component: JuryIndex,
    },
    {
      path: "proposal/:id/:juryDetailId",
      component: JuryProposal,
    },
    {
      path: "score/:phase/:user",
      name: "scoring",
      component: JuryScoring,
    },
  ],
} as RouteConfig;
