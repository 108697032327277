import { Observable } from "rxjs";
import { mapToArrayClass, mapToClass, mapToData } from "@/client/transformers";
import PeriodManagement from "@/models/period-management";
import { AxiosRequestConfig } from "axios";

export class PeriodManagementService {
  public static getPeriodManagements(): Observable<PeriodManagement[]> {
    return window.axios
      .get("/periodManagement")
      .pipe(mapToData(), mapToArrayClass(PeriodManagement));
  }

  public static getPeriodManagementsByYear(
    year: string | number,
    options: AxiosRequestConfig = {}
  ): Observable<PeriodManagement[]> {
    return window.axios
      .get(`/periodManagement/getbyyear/${year}`, options)
      .pipe(mapToData(), mapToArrayClass(PeriodManagement));
  }

  public static editTime(params: {
    periodId: string;
    start: string;
    end: string;
  }): Observable<PeriodManagement> {
    return window.axios
      .put(`/periodManagement/EditTime`, null, { params })
      .pipe(mapToData(), mapToClass(PeriodManagement));
  }

  public static startPeriod(periodId: string): Observable<PeriodManagement> {
    return window.axios
      .post(`/periodManagement/startperiod/${periodId}`)
      .pipe(mapToData(), mapToClass(PeriodManagement));
  }

  public static sendEmail(periodId: string): Observable<PeriodManagement> {
    return window.axios
      .post(`/periodManagement/${periodId}/SendEmail`)
      .pipe(mapToData(), mapToClass(PeriodManagement));
  }
}
