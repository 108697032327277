import Vue from "vue";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import {
//   TooltipPlugin,
//   ModalPlugin,
//   ToastPlugin,
//   CardPlugin,
//   CollapsePlugin,
//   ButtonPlugin,
//   ButtonGroupPlugin,
//   FormDatepickerPlugin,
//   FormSelectPlugin,
//   FormPlugin,
//   FormGroupPlugin,
//   FormInputPlugin,
//   FormTextareaPlugin,
//   FormFilePlugin,
//   FormRadioPlugin,
//   TablePlugin,
//   LayoutPlugin,
//   IconsPlugin,
//   SpinnerPlugin,
//   BadgePlugin,
//   ImagePlugin,
//   OverlayPlugin,
// } from "bootstrap-vue";
import "bootstrap/dist/js/bootstrap.min.js";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

// Vue.use(ModalPlugin, {
//   BTable: {
//     bordered: true,
//     small: true,
//     showEmpty: true,
//     striped: true,
//   },
//   BButton: {
//     variant: "primary",
//   },
// });
// Vue.use(ToastPlugin);
// Vue.use(TooltipPlugin);
// Vue.use(CardPlugin);
// Vue.use(CollapsePlugin);
// Vue.use(ButtonPlugin);
// Vue.use(ButtonGroupPlugin);
// Vue.use(FormDatepickerPlugin);
// Vue.use(FormPlugin);
// Vue.use(FormGroupPlugin);
// Vue.use(FormInputPlugin);
// Vue.use(FormTextareaPlugin);
// Vue.use(FormSelectPlugin);
// Vue.use(FormDatepickerPlugin);
// Vue.use(FormFilePlugin);
// Vue.use(FormRadioPlugin);
// Vue.use(TablePlugin);
// Vue.use(LayoutPlugin);
// Vue.use(IconsPlugin);
// Vue.use(SpinnerPlugin);
// Vue.use(BadgePlugin);
// Vue.use(ImagePlugin);
// Vue.use(OverlayPlugin);

Vue.use(BootstrapVue, {
  BTable: {
    bordered: true,
    small: true,
    showEmpty: true,
    striped: true,
  },
  BButton: {
    variant: "primary",
  },
});
Vue.use(IconsPlugin);
