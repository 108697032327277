import {
  NavigationGuard,
  NavigationGuardNext,
  Route,
  RouteConfig,
} from "vue-router";
import store, { StoreData } from "@/store";
import { tap } from "rxjs/operators";
import { combineLatest } from "rxjs";
import { Store } from "vuex";

// participant
const ParticipantTemplate = () =>
  import("@/templates/participant/participant.template.vue");
const ParticipantIndex = () =>
  import("@/components/participant/index/participant-index.vue");
const ParticipantVote = () =>
  import("@/components/participant/vote/participant-vote.vue");
const ParticipantFeedback = () =>
  import("@/components/participant/feedback/participant-feedback.vue");
const CreateFeedback = () =>
  import("@/components/participant/feedback/create-feedback.vue");
const ParticipantFeedbackDetail = () =>
  import("@/components/participant/feedback/participant-feedback-detail.vue");
const CreateProposal = () =>
  import("@/components/participant/proposal/create-proposal.vue");
const ProposalApproval = () =>
  import("@/components/participant/proposal/proposal-approval.vue");
const EntryProposalFirst = () =>
  import("@/components/participant/proposal/entry-proposal-first.vue");
const EntryProposalSecond = () =>
  import("@/components/participant/proposal/entry-proposal-second.vue");
const ViewProposalFirst = () =>
  import("@/components/participant/proposal/view-proposal-first.vue");
const ViewProposalSecond = () =>
  import("@/components/participant/proposal/view-proposal-second.vue");

const participantMiddleware: NavigationGuard = (
  _to: Route,
  _from: Route,
  next: NavigationGuardNext<Vue>
) => {
  const year = (store as Store<StoreData>).state.Common.currentYear;
  Promise.all([
    store.dispatch("AuthStore/getUser"),
    store.dispatch("PeriodManagementStore/getPeriodManagementsByYear", {
      year,
    }),
  ]).then((res) => {
    combineLatest(res)
      .pipe(
        tap(() => {
          if (
            store.getters["AuthStore/isParticipant"] ||
            store.getters["AuthStore/isCommitee"] ||
            (store.getters["AuthStore/isJury"] &&
              store.getters["PeriodManagementStore/isParticipantPeriod"])
          ) {
            return next();
          } else {
            return next("/");
          }
        })
      )
      .subscribe();
  });
};

export default {
  path: "/participant",
  component: ParticipantTemplate,
  beforeEnter: participantMiddleware,
  children: [
    {
      path: "/",
      name: "Home",
      component: ParticipantIndex,
    },
    // {
    //   path: "proposal/create",
    //   name: "Create Proposal",
    //   component: CreateProposal,
    // },
    {
      path: "proposal/import",
      name: "Import Proposal",
      component: () =>
        import("@/components/participant/proposal/import-proposal.vue"),
    },
    {
      path: "proposal/:id",
      name: "Proposal",
      component: ProposalApproval,
    },
    {
      path: "proposal/1/:id/entry",
      name: "Entry Proposal Phase 1",
      component: EntryProposalFirst,
    },
    {
      path: "proposal/2/:id/entry",
      name: "Entry Proposal Phase 2",
      component: EntryProposalSecond,
    },
    {
      path: "proposal/1/:id/view",
      name: "View Proposal Phase 1",
      component: ViewProposalFirst,
    },
    {
      path: "proposal/2/:id/view",
      name: "View Proposal Phase 2",
      component: ViewProposalSecond,
    },
    {
      path: "vote",
      name: "Voting",
      component: ParticipantVote,
    },
    {
      path: "feedback",
      name: "List Feedback",
      component: ParticipantFeedback,
    },
    {
      path: "feedback/create",
      name: "Create Feedback",
      component: CreateFeedback,
    },
    {
      path: "feedback/:id",
      component: ParticipantFeedbackDetail,
    },
  ],
} as RouteConfig;
