import { Paginate } from "@/models/paginate";
import Activity from "@/models/activity";
import ActivityDetail from "@/models/activity-detail";
import { ActivityService } from "@/services/activity.service";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { ActionContext, Module } from "vuex";
import { StoreData } from "@/store";

export interface ActivityData {
  activities: Paginate<Activity[]> | null;
  activityDetail: Paginate<ActivityDetail[]> | null;
}

export default {
  namespaced: true,
  state: (): ActivityData => ({
    activities: null,
    activityDetail: null,
  }),
  actions: {
    getActivities: (
      context: ActionContext<ActivityData, StoreData>,
      params: any
    ): Observable<Paginate<Activity[]>> => {
      return ActivityService.getActivities(params).pipe(
        tap((activities: Paginate<Activity[]>) => {
          context.commit("setActivities", activities);
        })
      );
    },

    getActivityDetail: (
      context: ActionContext<ActivityData, StoreData>,
      params: any
    ): Observable<Paginate<ActivityDetail[]>> => {
      return ActivityService.getActivityDetail(params.id, params.query).pipe(
        tap((activityDetail: Paginate<ActivityDetail[]>) => {
          context.commit("setActivityDetail", activityDetail);
        })
      );
    },
  },

  mutations: {
    setActivities: (
      state: ActivityData,
      activities: Paginate<Activity[]>
    ): void => {
      state.activities = activities;
    },

    setActivityDetail: (
      state: ActivityData,
      activityDetail: Paginate<ActivityDetail[]>
    ): void => {
      state.activityDetail = activityDetail;
    },
  },
} as Module<ActivityData, StoreData>;
