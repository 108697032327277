import Vue from "vue";
import "@/plugins/bootstrap-vue";
import "@/plugins/axios";
import "@/plugins/modal-toast";
import "@/registerServiceWorker";
import router from "@/router/router";
import store from "@/store";
import App from "@/app/app.vue";
import VueMoment from "vue-moment";
import moment from "moment";
import "moment/locale/id";

Vue.config.productionTip = false;

Vue.use(VueMoment, {
  moment,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
