import { Observable, of } from "rxjs";
import Proposal from "@/models/proposal";
import { Collection } from "@/models/collection";
import {
  mapToArrayClass,
  mapToArrayCollection,
  mapToClass,
  mapToCollection,
  mapToData,
  mapToPagination,
} from "@/client/transformers";
import { HttpUtil } from "@/client/http.util";
import CommentHeader from "@/models/comment-header";
import { Paginate } from "@/models/paginate";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";

export class ProposalService {
  public static getProposals(
    params: any = null
  ): Observable<Paginate<Array<Collection<Proposal>>>> {
    return window.axios.get(`/proposal`, { params }).pipe(mapToPagination());
  }

  public static getProposal(
    proposalId: string
  ): Observable<Collection<Proposal>> {
    return window.axios
      .get(`/proposal/${proposalId}`)
      .pipe(mapToData(), mapToCollection<Proposal>());
  }

  public static postProposal(params: any): Observable<any> {
    const team = JSON.stringify(params.team);
    return window.axios.post(
      `/proposal`,
      HttpUtil.payloadToFormData({ ...params, team })
    );
  }

  public static updateProposal(params: any): Observable<any> {
    return window.axios.put(`/proposal`, HttpUtil.payloadToFormData(params));
  }

  public static deleteProposal(proposalId: string): Observable<any> {
    return window.axios.delete(`/proposal/${proposalId}`);
  }

  public static updateOrder(params: any): Observable<Collection<Proposal>> {
    return window.axios
      .put(`/proposal/updateorder`, null, { params })
      .pipe(mapToData(), mapToCollection<Proposal>());
  }

  public static addJudge(params: any): Observable<Collection<Proposal>> {
    return window.axios
      .post(`/proposal/addjudge`, null, { params })
      .pipe(mapToData(), mapToCollection<Proposal>());
  }

  public static deleteJudge(params: any): Observable<Collection<Proposal>> {
    return window.axios
      .delete(`/proposal/deletejudge`, { params })
      .pipe(mapToData(), mapToCollection<Proposal>());
  }

  public static finalizeJuryDetail(params: any): AxiosObservable<any> {
    return window.axios.put(`/proposal/proposaljurydetail/finalize`, null, {
      params,
    });
  }

  public static getJudgeProposals(
    params?: any,
    options: any = {}
  ): Observable<Array<Collection<Proposal>>> {
    Object.assign(options, { params });
    return window.axios
      .get(`/judge/proposals`, options)
      .pipe(mapToData(), mapToArrayCollection<Proposal>());
  }

  public static getParticipantProposals(): Observable<
    Array<Collection<Proposal>>
  > {
    return window.axios
      .get(`/proposal/groupbyphase`)
      .pipe(mapToData(), mapToArrayCollection<Proposal>());
  }

  public static setProposalApproval(params?: any): Observable<any> {
    return window.axios.put(`/proposal/approval`, params);
  }

  public static isAllowToSubmit(): Observable<any> {
    return window.axios.get(`/proposal/isallowtosubmit`).pipe(mapToData());
  }

  public static saveProposalSummary(params?: any): Observable<any> {
    return window.axios.put(`/proposal/entryproposal/projectsummary`, params);
  }

  public static saveProposalBody(params?: any): Observable<any> {
    return window.axios.put(`/proposal/entryproposal/bodyproposal`, params);
  }

  public static createProposalLiterature(params?: any): Observable<any> {
    return window.axios.post(`/proposal/entryproposal/chapter2`, params);
  }

  public static updateProposalLiterature(params?: any): Observable<any> {
    return window.axios.put(`/proposal/entryproposal/chapter2`, params);
  }

  public static deleteProposalLiterature(params?: any): Observable<any> {
    return window.axios.delete(`/proposal/entryproposal/chapter2`, { params });
  }

  public static saveProposalImplementation(params?: any): Observable<any> {
    return window.axios.put(`/proposal/entryproposal/chapter3`, params);
  }

  public static createProposalReference(params?: any): Observable<any> {
    return window.axios.post(`/proposal/entryproposal/reference`, params);
  }

  public static updateProposalReference(params?: any): Observable<any> {
    return window.axios.put(`/proposal/entryproposal/reference`, params);
  }

  public static deleteProposalReference(params?: any): Observable<any> {
    return window.axios.delete(`/proposal/entryproposal/reference`, { params });
  }

  public static createProposalAppendix(params?: any): Observable<any> {
    return window.axios.post(`/proposal/entryproposal/appendix`, params);
  }

  public static updateProposalAppendix(params?: any): Observable<any> {
    return window.axios.put(`/proposal/entryproposal/appendix`, params);
  }

  public static deleteProposalAppendix(params?: any): Observable<any> {
    return window.axios.delete(`/proposal/entryproposal/appendix`, { params });
  }

  public static finalizeProposal(params?: any): Observable<any> {
    return window.axios.post(`/proposal/entryproposal/finalize`, params);
  }

  public static savePoster(params?: any): Observable<any> {
    return window.axios.post(
      `/proposal/entryproposal/poster`,
      HttpUtil.payloadToFormData(params)
    );
  }

  public static savePresentationFile(params?: any): Observable<any> {
    return window.axios.post(
      `/proposal/entryproposal/presentationFile`,
      HttpUtil.payloadToFormData(params)
    );
  }

  public static getProposalComments(params?: any): Observable<CommentHeader[]> {
    return window.axios
      .get(`/proposal/comments`, { params })
      .pipe(mapToData(), mapToArrayClass(CommentHeader));
  }

  public static postProposalComment(params?: any): Observable<any> {
    return window.axios.post(`/proposal/comments`, params);
  }

  public static putProposalComment(params?: any): Observable<any> {
    return window.axios.put(`/proposal/comments`, params);
  }

  public static deleteProposalComment(commentId: string): Observable<any> {
    return window.axios.delete(`/proposal/comments/${commentId}`);
  }

  public static saveTop15(params?: any): Observable<any> {
    return window.axios.post(`/proposal/savetop15`, params);
  }

  public static saveTop3(params?: any): Observable<any> {
    return window.axios.post(`/proposal/savetop3`, params);
  }

  public static sendEmailReminderToFinalize(params?: any): Observable<any> {
    return window.axios.post(`/proposal/SendEmailReminderToFinalize`, params);
  }

  public static sendEmailReminderToApproval(params?: any): Observable<any> {
    return window.axios.post(`/proposal/SendEmailReminderToApproval`, params);
  }
}
