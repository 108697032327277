import * as Msal from "msal";
import { msalConfig, loginRequest, clientScope } from "@/client/msal.config";
import { mapToData } from "@/client/transformers";
import { Observable } from "rxjs";
import UserHeader from "@/models/user-header";

export class AuthService {
  public static msalInstance: Msal.UserAgentApplication = new Msal.UserAgentApplication(
    msalConfig
  );

  public static login(): void {
    AuthService.handleRedirectCallback();
    return (
      AuthService.msalInstance
        // .loginPopup(loginRequest);
        .loginRedirect(loginRequest)
    );
  }

  public static handleRedirectCallback(): void {
    AuthService.msalInstance.handleRedirectCallback(console.error);
  }

  public static async acquireTokenGraph(): Promise<Msal.AuthResponse | void> {
    return AuthService.msalInstance
      .acquireTokenSilent(loginRequest)
      .catch((error) => {
        // if (
        //   error.errorCode === "consent_required" ||
        //   error.errorCode === "interaction_required" ||
        //   error.errorCode === "login_required"
        // ) {
          return AuthService.msalInstance.acquireTokenRedirect(loginRequest);
        // }
      });
  }

  public static async acquireTokenClient(): Promise<Msal.AuthResponse | void> {
    return AuthService.msalInstance
      .acquireTokenSilent(clientScope)
      .catch((error) => {
        // if (
        //   error.errorCode === "consent_required" ||
        //   error.errorCode === "interaction_required" ||
        //   error.errorCode === "login_required"
        // ) {
          return AuthService.msalInstance.acquireTokenRedirect(clientScope);
        // }
      });
  }

  public static logout(): void {
    return AuthService.msalInstance.logout();
  }

  public static getAccount(): Msal.Account {
    return AuthService.msalInstance.getAccount();
  }

  public static getMsal(): Msal.UserAgentApplication {
    return AuthService.msalInstance;
  }

  public static getUser(): Observable<{ user: UserHeader }> {
    return window.axios.get(`/auth`).pipe(mapToData());
  }
}
