import { defer, from, Observable, of } from "rxjs";
import {
  mapToClass,
  mapToData,
  filterObject,
  mapToPagination,
  mapToArrayClass,
} from "@/client/transformers";
import UserHeader from "@/models/user-header";
import { Paginate } from "@/models/paginate";
import { AuthService } from "@/services/auth.service";
import axios from "axios-observable";
import { mergeMap } from "rxjs/operators";
import UserSuggestion from "@/models/user-suggestion";
import TeamHeader from "@/models/team-header";
import { HttpUtil } from "@/client/http.util";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";

export class UserService {
  public static getUsers(params: any): Observable<Paginate<UserHeader[]>> {
    return window.axios
      .get(`/user`, { params: filterObject(params) })
      .pipe(mapToPagination());
  }

  public static getUser(userId: string): Observable<UserHeader> {
    return window.axios
      .get(`/user/${userId}`)
      .pipe(mapToData(), mapToClass(UserHeader));
  }

  public static updateUser(query: any, body: any): AxiosObservable<UserHeader> {
    return window.axios.put(`/user`, body, { params: query });
  }

  public static getTeams(params: any): Observable<Paginate<TeamHeader[]>> {
    return window.axios
      .get(`/team`, { params: filterObject(params) })
      .pipe(mapToPagination());
  }

  public static getTeam(teamId: string): Observable<TeamHeader> {
    return window.axios
      .get(`/team/${teamId}`)
      .pipe(mapToData(), mapToClass(TeamHeader));
  }

  public static updateTeam(params: any): AxiosObservable<UserHeader> {
    const team = JSON.stringify(params.team);
    return window.axios.put(
      `/team`,
      HttpUtil.payloadToFormData({ ...params, team })
    );
  }

  public static postUser(params: any): Observable<UserHeader> {
    return window.axios
      .post(`/user`, params)
      .pipe(mapToData(), mapToClass(UserHeader));
  }

  public static deleteUser(userId: string): Observable<UserHeader> {
    return window.axios
      .delete(`/user/${userId}`)
      .pipe(mapToData(), mapToClass(UserHeader));
  }

  public static getJobBands(): Observable<string[]> {
    return window.axios.get(`/user/getjobbands`).pipe(mapToData());
  }

  public static getRoles(): Observable<string[]> {
    return window.axios.get(`/user/getroles`).pipe(mapToData());
  }

  public static getUserInformation(email: string): Observable<any> {
    return window.axios.get(`/user/getbyemail/${email}`);
  }

  public static getUserSuggestion(
    keyword: string
  ): Observable<UserSuggestion[]> {
    return defer(() => from(AuthService.acquireTokenGraph())).pipe(
      mergeMap((res) => {
        if (res && "accessToken" in res) {
          const config = {
            headers: {
              Authorization: `Bearer ${res.accessToken}`,
            },
          };
          return axios.get(
            `https://graph.microsoft.com/v1.0/users?$filter=startsWith(Mail,'${keyword}')%20or%20startsWith(givenName,'${keyword}')%20or%20startsWith(surname,'${keyword}')&$select=displayName,surname,mail&$top=10`,
            config
          );
        }
        return of({ data: [] });
      }),
      mapToData(),
      mergeMap((res) => {
        return of(res.value);
      }),
      mapToArrayClass(UserSuggestion)
    );
  }
}
