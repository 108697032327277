export class HttpUtil {
  public static queryParamsExtractor(obj: any): any {
    const data: any = {};
    for (const key in obj) {
      if (undefined !== obj[key] && null !== obj[key]) {
        if (Array.isArray(obj[key])) {
          data[`${key}[]`] = [];
          obj[key].forEach((item: any) => {
            data[`${key}[]`].push(item);
          });
        } else if (typeof obj[key] === "object") {
          for (const objKey in obj[key]) {
            data[`${key}[${objKey}]`] = obj[key][objKey];
          }
        } else {
          data[key] = obj[key];
        }
      }
    }
    return this.requestToSnake(data);
  }

  public static requestToSnake(data: any): any {
    if (data instanceof FormData) {
      const arr: any[] = Array.from((<any>data).entries());

      return arr.reduce((acc, [key, val]) => {
        const newKey = key.replace(
          /([A-Z])/g,
          (m: string) => "_" + m.toLowerCase()
        );
        if (val) {
          if (val instanceof File) {
            acc.append(newKey, val, val.name);
          } else {
            acc.append(newKey, val);
          }
        }

        return acc;
      }, new FormData());
    }
    if (
      data instanceof Date ||
      data instanceof Boolean ||
      data instanceof String
    ) {
      return data;
    }
    if (data instanceof Array) {
      return data.map((item: any) => this.requestToSnake(item));
    }

    if (null !== data && "object" === typeof data) {
      const transformed: any = {};
      Object.keys(data).forEach((key: any) => {
        let transformedKey = key;
        let transformedItem = data[key];

        if ("string" === typeof transformedKey) {
          transformedKey = transformedKey.replace(
            /([A-Z])/g,
            (m: string) => "_" + m.toLowerCase()
          );
        }

        if ("object" === typeof transformedItem) {
          transformedItem = this.requestToSnake(transformedItem);
        }

        transformed[transformedKey] = transformedItem;
      });

      return transformed;
    }

    return data;
  }

  public static errorExtractor(err: any): string {
    let message: any = "";

    if (err.error && err.message) {
      if (err.error instanceof ProgressEvent) {
        message = err.message;
      } else {
        message = err.error.message;
      }
    }

    return message;
  }

  public static payloadToFormData(payload: any): FormData {
    const formData = new FormData();

    const subs = (data: any, prefix?: string) => {
      for (const key in data) {
        const p = prefix ? `${prefix}[${key}]` : key;
        if (data[key] instanceof File) {
          if (data[key]) {
            formData.append(p, data[key]);
          }
        } else if (data[key] instanceof Array || data[key] instanceof Object) {
          subs(data[key], p);
        } else {
          if (data[key]) {
            formData.append(p, data[key]);
          }
        }
      }
    };

    subs(payload);

    return formData;
  }

  public static objectToFormData(
    formData: FormData,
    key: string,
    payload: any
  ): FormData {
    const keys = Object.keys(payload);
    keys.forEach((item) => {
      formData.append(`${key}[${item}]`, payload[item]);
    });

    return formData;
  }
}
