import * as Msal from "msal";

console.log(
  process.env.VUE_APP_LOGIN_URL,
  window.location.origin,
  process.env.NODE_ENV
);

export const msalConfig: Msal.Configuration = {
  auth: {
    clientId:
      process.env.VUE_APP_AZURE_AD_CLIENT_ID ||
      "7ee15a7a-5992-42b4-8e11-b88cb35288fe",
    authority:
      "https://login.microsoftonline.com/" +
      (process.env.VUE_APP_AZURE_AD_TENANT_ID ||
        "3485b963-82ba-4a6f-810f-b5cc226ff898"),
    redirectUri:
      process.env.VUE_APP_LOGIN_URL || window.location.origin + "/login",
    postLogoutRedirectUri:
      process.env.VUE_APP_LOGIN_URL || window.location.origin + "/login",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    tokenRenewalOffsetSeconds: 55 * 60,
  },
};

export const loginRequest: Msal.AuthenticationParameters = {
  scopes: ["openid", "profile", "User.Read", "People.Read"],
  // forceRefresh: true,
};

export const clientScope: Msal.AuthenticationParameters = {
  scopes: [
    process.env.VUE_APP_AZURE_AD_CLIENT_ID ||
      "7ee15a7a-5992-42b4-8e11-b88cb35288fe",
  ],
  // forceRefresh: true,
};
