import { Collection } from "@/models/collection";
import Proposal from "@/models/proposal";
import { ProposalService } from "@/services/proposal.service";
import { tap } from "rxjs/operators";
import CommentHeader from "@/models/comment-header";
import { Paginate } from "@/models/paginate";
import { Observable } from "rxjs-compat";
import { ActionContext, Module } from "vuex";
import { StoreData } from "@/store";

export interface ProposalData {
  proposals: Paginate<Array<Collection<Proposal>>> | null;
  proposal: Collection<Proposal> | null;
  isAllowToSubmit: {
    isAllowToSubmit: boolean;
    message: string;
  } | null;
  mainComments: CommentHeader[];
  comments: CommentHeader[];
}

export default {
  namespaced: true,
  state: (): ProposalData => ({
    proposals: null,
    proposal: null,
    isAllowToSubmit: null,
    mainComments: [],
    comments: [],
  }),
  actions: {
    getProposals: (
      context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.getProposals(params).pipe(
        tap((proposals: Paginate<Array<Collection<Proposal>>>): any => {
          context.commit("setProposals", proposals);
        })
      );
    },

    getProposal: (
      context: ActionContext<ProposalData, StoreData>,
      proposalId: string
    ): any => {
      // context.state.proposal = null;
      return ProposalService.getProposal(proposalId).pipe(
        tap((proposal: Collection<Proposal>): void => {
          context.commit("setProposal", proposal);
        })
      );
    },

    postProposal: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.postProposal(params);
    },

    updateProposal: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.updateProposal(params);
    },

    deleteProposal: (
      _context: ActionContext<ProposalData, StoreData>,
      proposalId: string
    ): any => {
      return ProposalService.deleteProposal(proposalId);
    },

    updateOrder: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.updateOrder(params);
    },

    setProposalApproval: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.setProposalApproval(params);
    },

    addJudge: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): Observable<Collection<Proposal>> => {
      return ProposalService.addJudge(params);
    },

    deleteJudge: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): Observable<Collection<Proposal>> => {
      return ProposalService.deleteJudge(params);
    },

    finalizeJuryDetail: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.finalizeJuryDetail(params);
    },

    getJudgeProposals: (
      context: ActionContext<ProposalData, StoreData>,
      { params, options }: { params: any; options: any }
    ): any => {
      return ProposalService.getJudgeProposals(params, options).pipe(
        tap((proposals: Array<Collection<Proposal>>): any => {
          context.commit("setProposals", proposals);
        })
      );
    },

    checkAllowToSubmit: (
      context: ActionContext<ProposalData, StoreData>
    ): any => {
      return ProposalService.isAllowToSubmit().pipe(
        tap((isAllowToSubmit): void => {
          context.commit("setAllowToSubmit", isAllowToSubmit);
        })
      );
    },

    getParticipantProposals: (
      context: ActionContext<ProposalData, StoreData>
    ): any => {
      return ProposalService.getParticipantProposals().pipe(
        tap((proposals: Array<Collection<Proposal>>): void => {
          context.commit("setProposals", proposals);
        })
      );
    },

    saveProposalSummary: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.saveProposalSummary(params);
    },

    saveProposalBody: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.saveProposalBody(params);
    },

    createProposalLiterature: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.createProposalLiterature(params);
    },

    updateProposalLiterature: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.updateProposalLiterature(params);
    },

    deleteProposalLiterature: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.deleteProposalLiterature(params);
    },

    saveProposalImplementation: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.saveProposalImplementation(params);
    },

    createProposalReference: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.createProposalReference(params);
    },

    updateProposalReference: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.updateProposalReference(params);
    },

    deleteProposalReference: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.deleteProposalReference(params);
    },

    createProposalAppendix: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.createProposalAppendix(params);
    },

    updateProposalAppendix: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.updateProposalAppendix(params);
    },

    deleteProposalAppendix: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.deleteProposalAppendix(params);
    },

    finalizeProposal: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.finalizeProposal(params);
    },

    savePoster: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.savePoster(params);
    },

    savePresentationFile: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.savePresentationFile(params);
    },

    getMainComments: (
      context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.getProposalComments(params).pipe(
        tap((comments): void => {
          context.commit("setMainComments", comments);
        })
      );
    },

    getComments: (
      context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.getProposalComments(params).pipe(
        tap((comments): void => {
          context.commit("setComments", comments);
        })
      );
    },

    postComment: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.postProposalComment(params);
    },

    updateComment: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.putProposalComment(params);
    },

    deleteComment: (
      _context: ActionContext<ProposalData, StoreData>,
      commentId: string
    ): any => {
      return ProposalService.deleteProposalComment(commentId);
    },

    saveTop15: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.saveTop15(params);
    },

    saveTop3: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.saveTop3(params);
    },

    sendEmailReminderToFinalize: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.sendEmailReminderToFinalize(params);
    },

    sendEmailReminderToApproval: (
      _context: ActionContext<ProposalData, StoreData>,
      params: any
    ): any => {
      return ProposalService.sendEmailReminderToApproval(params);
    },
  },
  mutations: {
    setProposals: (
      state: ProposalData,
      proposals: Paginate<Array<Collection<Proposal>>>
    ): void => {
      state.proposals = proposals;
    },

    setProposal: (
      state: ProposalData,
      proposal: Collection<Proposal>
    ): void => {
      state.proposal = proposal;
    },

    setAllowToSubmit: (state: ProposalData, isAllowToSubmit: any): void => {
      state.isAllowToSubmit = isAllowToSubmit;
    },

    setMainComments: (state: ProposalData, comments: CommentHeader[]): void => {
      state.mainComments = comments;
    },

    setComments: (state: ProposalData, comments: CommentHeader[]): void => {
      state.comments = comments;
    },
  },
} as Module<ProposalData, StoreData>;
