import { ReportService } from "@/services/report.service";
import { tap } from "rxjs/operators";
import Report from "@/models/report";
import { ActionContext, Module } from "vuex";
import { StoreData } from "@/store";

export interface ReportData {
  reports: Report[];
}

export default {
  namespaced: true,
  state: (): ReportData => ({
    reports: [],
  }),
  actions: {
    getReports: (
      context: ActionContext<ReportData, StoreData>,
      params: any
    ): any => {
      return ReportService.getReports(params).pipe(
        tap((reports: Report[]) => {
          context.commit("setReports", reports);
        })
      );
    },

    downloadReport: (
      _context: ActionContext<ReportData, StoreData>,
      params: any
    ): any => {
      return ReportService.downloadReport(params.reportType, params.year).pipe(
        tap((res) => {
          const disposition = res.headers["content-disposition"].split(";");
          const item = disposition.find((item: string) =>
            item.startsWith("filename")
          );
          const fileName = item.replace("filename=", "");

          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
      );
    },
  },
  mutations: {
    setReports: (state: ReportData, reports: Report[]): void => {
      state.reports = reports;
    },
  },
} as Module<ReportData, StoreData>;
