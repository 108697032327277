import { tap } from "rxjs/operators";
import ScoringDetail from "@/models/scoring-detail";
import { JuryDetailService } from "@/services/jury-detail.service";
import JuryDetail from "@/models/jury-detail";
import { ActionContext, Module } from "vuex";
import { StoreData } from "@/store";
import { Observable } from "rxjs";

export interface JuryDetailData {
  scoringDetails: any[];
  juryDetail: JuryDetail | null;
}

export default {
  namespaced: true,
  state: (): JuryDetailData => ({
    scoringDetails: [],
    juryDetail: null,
  }),
  actions: {
    getJuryDetail: (
      context: ActionContext<JuryDetailData, StoreData>,
      { params, options = {} }: { params: any; options: any }
    ): Observable<JuryDetail> => {
      return JuryDetailService.getJuryDetail(params, options).pipe(
        tap((juryDetail: JuryDetail) => {
          context.commit("setJuryDetail", juryDetail);
        })
      );
    },

    getProposalScoringDetails: (
      context: ActionContext<JuryDetailData, StoreData>,
      proposalId: string
    ): Observable<Record<string, string | number>[]> => {
      return JuryDetailService.getProposalScoringDetails(proposalId).pipe(
        tap((scoringDetails: Record<string, string | number>[]) => {
          context.commit("setScoringDetails", scoringDetails);
        })
      );
    },

    submitScoringDetails: (
      _context: ActionContext<JuryDetailData, StoreData>,
      { params, options = {} }: { params: any; options: any }
    ): any => {
      return JuryDetailService.submitScoringDetails(params, options);
    },

    finalizeScoringDetails: (
      _context: ActionContext<JuryDetailData, StoreData>,
      params: any
    ): any => {
      return JuryDetailService.finalizeScoringDetails(params);
    },

    sendApprovalCode: (
      _context: ActionContext<JuryDetailData, StoreData>
    ): any => {
      return JuryDetailService.sendApprovalCode();
    },
  },
  mutations: {
    setScoringDetails: (
      state: JuryDetailData,
      scoringDetails: Record<string, string | number>[][]
    ): any => {
      state.scoringDetails = scoringDetails;
    },
    setJuryDetail: (state: JuryDetailData, juryDetail: JuryDetail): any => {
      state.juryDetail = juryDetail;
    },
  },
} as Module<JuryDetailData, StoreData>;
