import Proposal from "@/models/proposal";
import ScoringDetail from "@/models/scoring-detail";
import { UUIDModel } from "@/models/uuid-model";
import { UUID } from "@/models/uuid";

export default class JuryDetail extends UUIDModel<JuryDetail> {
  public juryDetailId: UUID = new UUID();
  public phase = 0;
  public emailJury = "";
  public approveScore = false;
  public approvalCode = "";
  public approvalDate = "";
  public assignDate = "";

  public proposalId: UUID = new UUID();
  public proposal: Proposal = new Proposal();

  public scoringDetail: ScoringDetail[] = [];
}

export interface FinalizeScoreEntryParams {
  JuryDetailId: string;
  ApprovalCode: string;
}
